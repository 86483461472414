.dinein-buttons {
  padding: 4px 5px;
  margin: 0px 24px 10px;
  background-color: #fafafa;
  border-radius: 12px;
  border: 1px solid #c0cdd7;
  display: flex;
  justify-content: space-between;
}

.dinein-buttons button {
  color: #40484e;
  font-size: 14px;
  line-height: 28px;
  font-weight: bold;
  width: 155px;
  background-color: transparent !important;
}

.dinein-buttons .dinein-active {
  background-color: #98694e !important;
  border-radius: 12px;
  border: 1px solid #c0cdd7;
  color: #fff !important;
}

.dinein-active:hover {
  background-color: #98694E !important;
}

.dinein-heading-text {
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  color: #1f2933 !important;
  margin-top: 8px !important;
  padding-left: 24px !important;
}
