.button-section {
  width: 100%;
  margin-top: 150px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}

.catering_inner_container {
  width: 326px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 170px;
}

.catering_button_container {
  width: 326px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  text-align: center;
}
