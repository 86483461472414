.menu_card_list_outer_container {
  height: auto;
  background-color: #f6f7f9;
  display: flex;
  justify-content: center;
  padding: 26px 0;
  margin-bottom: 30px;
}

.menu_card_list_inner_container {
  min-width: 326px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: -45px;
}

.menu-items-wrapper {
  height: 500px;
  width: 105%;
  /* height: 100%; */
  overflow-y: scroll;
  padding-right: 25px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box;
  scrollbar-width: none;
}

.menu-items-wrapper::-webkit-scrollbar {
  display: none;
}

.comment_button_container {
  display: flex;
  justify-content: end;
  background-color: transparent;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.comment_button_container button {
  border: none;
  background-color: transparent;
}

.card_list_title_search_container {
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card_list_title_search_container.expanded_search {
  width: 326px;
  height: 30px;
  display: flex;
  position: relative;
}

.card_list_title {
  font-family: Titillium Web;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: -4px !important;
  /* margin-top: 16px !important; */
  color: #1f2933;
}

.card_list_search_container {
  display: flex;
  justify-content: flex-end;
}

.search {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  transition: width 0.3s ease;
  cursor: pointer;
  border-radius: 50%;
}

.searchbar {
  border-radius: 4px;
  color: #959fa8;
  font-family: Titillium Web;
  font-size: 16px;
  font-weight: 600;
  width: 0;
  transition: width 0.3s ease;
  border: none;
}

.searchicon {
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.search.expanded_search {
  position: absolute;
  width: 326px;
  height: 100%;
  border-radius: 24px;
  padding: 5px 0px;
  height: 30px;
  background-color: white;
}

.search.expanded_search .searchicon {
  width: 25px;
  height: 25px;
  border-radius: 0%;
  margin-top: 3px;
}

.search.expanded_search .searchbar {
  width: 286px;
  height: 30px;
}

.searchbar {
  border: none;
  background-color: transparent;
  outline: none;
}

.searchbar:focus {
  border: 1px solid #fdfcfc;
}

.element {
  transition: all 0.3s;
}

/* .search-expanded .element {
  transform: translateX(-100%);
  margin-left: 25px;
} */

/* .searchwrapper.expanded_search {
  display: flex;
  height: 30px;
  margin: 10px 0px;
} */
.searchwrapper {
  position: relative;
  top: 29px;
  align-self: flex-end
}

.Modal_Search_Item {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 375px;
  padding: 0 25px;
}

.closebtnmodal {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
}

.InputSearchModal {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 20px 25px; */
  height: 45px;
}

.InputModal {
  width: 100%;
  padding: 10px 20px;
  border: 1px solid #c0cdd7;
  border-radius: 15px;
  font-weight: 600;
  color: #959fa8;
}

.closebtn {
  width: 42px !important;
  height: 42px;
  border-radius: 50% !important;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.closebtn:hover {
  background-color: #fff;
}

.closebtn:active {
  background-color: #fff !important;
}

@media (max-width: 500px) {
  .Modal_Search_Item {
    width: 100%;
  }
}
.categoryItemsList {
  margin-bottom: 10px;
}

.list-menu-cards {
  height: 670px;
  overflow: auto;
  margin-top: 10px;
  padding-right: 10px;
  scrollbar-width: thin;
}

/* @media screen and (max-width: 500px) and (min-width: 380px) {
  .Nav_Image_Container img {
    width: 100%;
  }
  .Nav_Image_Container {
    margin-right: -50px !important;
  }
  .shopping_container {
    margin-right: 65px !important;
  }
}  */

/* @media screen and (max-width: 350px) {
  .Nav_Image_Container img {
    width: 100%;
  }

  .shopping_container {
    margin-right: 12px !important;
  }

  .Nav_Image_Container {
    margin-left: -59px;
  }

  .back_button {
    margin-left: 65px !important;
  } */

.scrollable-container {
  max-height: calc(100vh - 56px);
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  width: 100%;
}

.scrollable-container::-webkit-scrollbar {
  display: none;
}

.scrollable-container-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
