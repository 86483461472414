.menu_card_outer_container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0px 4px 16px rgba(116, 134, 148, 0.26);
  position: relative;
  width: 100%;
}

.menu_card_active_indicator {
  width: 6px;
  height: 100%;
  background-color: #026754;
  position: absolute;
  left: 0;
  top: 0;
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
  cursor: pointer;
}
.food-image {
  width: 100px;
  height: 100px;
  border-radius: 12px;
}

.menu_card_inner_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.menu_card_image_container {
  width: 100px;
  height: 100px;
}

.menu_card_price_text_container {
  padding: 4px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.price-ar {
  margin-right: 8px !important;
  margin-left: 0px !important;
}

.menu_card_heading_text {
  font-family: Titillium Web;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #40484e;
}

.menu_card_sub_text {
  font-family: Titillium Web;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #40484e;
  width: 145px;
}

.menu_card_price_add_to_cart_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Titillium Web;
}

.menu_card_price_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.menu_card_currency {
  font-size: 10px;
  color: #777777;
  font-weight: 600;
  margin-right: 5px;
}

.menu_card_item_price {
  font-size: 18px;
  font-weight: 700;
  color: #026754;
}

.menu_card_buttons_quantity_container {
  width: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.menu_card_buttons_quantity_container_active {
  width: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.menu_card_qunatity_label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #026754;
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  font-weight: 800;
}

.menu_card_add_to_cart_button_container {
  width: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.menu_card_add_to_cart_button {
  padding-top: 6px;
  text-align: center;
  border: none !important;
  background-color: #026754;
  width: 100%;
  height: 32px;
  border-radius: 12px !important;
}

.menu_card_add_to_cart_button img {
  width: 14px;
}

.menu_card_dropdown_container {
  width: 290px;
  background-color: white;
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.menu_card_dropdown_inner_container {
  width: 270px;
  margin-bottom: 10px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.menu_card_position_relative_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-wrapper {
  position: relative; /* This is needed to position the label absolutely */
}

.label {
  position: absolute;
  width: 52px;
  height: 16px;
  top: -7px;
  left: 10px;
  background-color: #00bf9b;
  border-radius: 0px 0px 5px 5px;
  /* Ensure the label is above the image */
  display: flex;
  align-items: center;
  justify-content: center;
}

.triangle {
  width: 0;
  height: 0;
  border-bottom: 7.5px solid #026754; /* Color of the triangle */
  border-right: 7px solid transparent;
  position: absolute;
  top: 1px;
  right: -6.5px; /* Position the triangle on the right edge of the label */
}
