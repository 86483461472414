.pickup-branches-container {
  position: relative;
  margin-bottom: 8px;
}

.pickup-branch-info {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e5e9ed;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 13px 7px 15px;
}

.pickup-restaurant-logo {
  width: 73px;
  height: 19px;
}

.pickup-restaurant-logo img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.pickup-branch-info h1 {
  font: normal normal 400 12px/8px Titillium Web;
  color: #40484e;
  display: block;
}

.pickup-branch-timings {
  margin-top: 5px;
}

.pickup-branch-details {
  display: flex;
}

.pickup-branch-details strong,
.pickup-branch-details span,
.pickup-order span {
  font: normal normal 400 7px/8px Titillium Web;
  color: #40484e;
}

.ar-distance {
  font-size: 9px !important;
}

.pickup-branch-status-section {
  display: flex;
  justify-content: center;
}

.branch-details {
  text-align: center;
}

.branch-details h1 {
  margin-bottom: 5px;
}

.pickup-order {
  text-align: end;
}

.pickup-order strong {
  font: normal normal 600 12px/8px Titillium Web;
  color: #40484e;
}

.pickup-button {
  display: flex;
}

.pickup-order button {
  border-radius: 16px;
  outline: none;
  font: normal normal 600 7px/8px Titillium Web;
  padding: 3px 10px;
  width: 100%;
  border: none;
}

.pickup-order .fill {
  background-color: #98694e;
  color: #fff;
}

.pickup-order .outline {
  background-color: #fff;
  color: #98694e;
  border: 1px solid #98694e;
}

.branch-status-tag {
  padding-top: 3px;
  text-align: center;
  font: 600 7px/8px Titillium Web;
  width: 68px;
  height: 16px;
  box-shadow: 0px 24px 48px #314f7c14;
  border-radius: 16px;
}

.branch-open-now {
  color: #00ce90;
  border: 1px solid #00d49b;
}

.branch-closed {
  color: #e02020;
  border: 1px solid #e02020;
  margin-left: auto;
}

.branch-closing-soon {
  color: #fa6400;
  border: 1px solid #fa6400;
}
