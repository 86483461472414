.queue-card-box {
  width: 300px;
  box-shadow: 0px 10px 30px rgba(49, 79, 124, 0.16);
  padding-bottom: 13px;
  border-radius: 16px;
  margin-bottom: 35px;
}

.dinein-queue-list {
  margin-top: 12px;
}

.dinein-slider {
  padding-left: 10px;
}

.queue-image {
  width: 300px;
  height: 276px;
  border-radius: 16px 16px 0px 0px;
}

.queue-card-detail-section {
  margin-top: -15px;
  background-color: #fff;
  position: relative;
  border-radius: 16px 16px 0px 0px;
  padding: 0px 15px;
}

.queue-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px 16px 0px 0px;
}

.queue-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 11px;
}

.queue-card-header h4 {
  font-size: 14px;
  color: #1f2933;
  line-height: 24px;
}

.queue-card-header p {
  font-size: 8px;
  color: #7f8fa6;
  line-height: 20px;
}

.queue-card-header button {
  padding: 0px;
  background-color: #1f2933;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.queue-card-leftside {
  display: flex;
}

.queue-card-header .branch-status-tag {
  margin-right: 8.5px;
}

.queue-location {
  margin-right: 7px !important;
}

.queue-status-details {
  display: flex;
  margin: 12px 0px;
}

.queue-status-details h3,
.queue-select-area h3 {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: #959fa8;
}

.table-available h3 {
  color: #ffffff !important;
}

.table-available img {
  filter: invert(100%) brightness(1.2);
}

.queue-status-details h1 {
  font-size: 36px;
  font-weight: 700;
  line-height: 14px;
  color: #1f2933;
}

.wait-status {
  display: flex;
  align-items: center;
  background-color: #f0f6f5;
  border-radius: 12px;
  padding: 7px 12px;
  position: relative;
}

.minutes-waiting {
  margin-left: 12px;
}

.minutes-waiting h3 {
  padding-left: 3px;
}

.queue-clock {
  width: 18px;
  height: 18px;
}

.wait-status button {
  padding: 0px;
  background-color: transparent;
  position: absolute;
  top: 6px;
  right: 7px;
}

.queue-card-box .join-queue {
  width: 100%;
  background-color: #98694e;
  border-radius: 16px;
  text-align: center;
  font-weight: 600;
  padding: 10px 0px;
  line-height: 24px;
  font-size: 16px;
  color: #ffffff;
}

.queue-card-box .join-queue:hover {
  background-color: #654938 !important;
}

.queue-card-box .save-edit {
  width: 50%;
  background-color: #98694e;
  border-radius: 16px;
  text-align: center;
  font-weight: 600;
  padding: 10px 0px;
  line-height: 24px;
  font-size: 16px;
  color: #ffffff;
}

.queue-card-box .save-edit:hover {
  background-color: #654938 !important;
}

.queue-card-box .join-queue.Mui-disabled {
  opacity: 0.3;
  color: #ffffff;
}

.dinein-queue-list .slick-dots li {
  width: 21px !important;
  margin-right: 6px;
  height: 20px !important;
}

.custom-paging {
  background-color: rgba(192, 205, 215, 0.46);
  border-radius: 3px;
  width: 21px;
  height: 6px;
}

.dinein-queue-list .slick-active > .custom-paging {
  background-color: #c0cdd7;
}

.dinein-queue-list .slick-list {
  padding: 0px !important;
  margin-right: -82px;
  margin-left: 12px;
}

.dinein-queue-list .slick-slide {
  visibility: visible;
}

.queue-guests-info {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.queue-select-area {
  display: flex;
  align-items: center;
}

.queue-guests {
  margin-left: 23px;
}

.queue-guests h3 {
  margin-right: 8px;
}

.indicatorContainer {
  padding: 0px !important;
}

.queue-form-container {
  margin-bottom: 8px;
}

.queue-form-input {
  height: 40px !important;
  margin-bottom: 0px !important;
}

.queue-btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.queue-btn-container .cancel,
.queue-btn-container .edit {
  width: 132px;
  /* padding: 10px 0px; */
  font-weight: 600;
}

.queue-btn-container .cancel {
  border: 1px solid #e02020;
  color: #e02020;
}

.queue-btn-container .edit {
  border: 1px solid #98694e;
}

.queue-dialog-vtn-container {
  margin: 20px 0px 25px;
}
.queue-dialog-vtn-container > div {
  display: flex;
  justify-content: center;
  gap: 8px;
}
.queue-dialog-vtn-container button {
  width: 116px !important;
  padding: 5px 0px !important;
  font-size: 14px !important;
  height: 29px !important;
  font-weight: 600 !important;
}

.queue-cancel-btn {
  background-color: #e02020 !important;
  color: #ffffff !important;
  margin-right: 8px !important;
}

.queue-edit-btn {
  background-color: #98694e !important;
  color: #ffffff !important;
}
