.details_outer_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 768px;
  height: 55px;
  margin-bottom: 12px;
  background-color: white;
  margin-top: -10px;
  z-index: 999;
  position: relative;
}

.details_inner_div {
  width: 326px;
  height: 100%;
}

.logo_info_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Titillium Web;
  font-style: Regular 400;
  font-weight: 400;
  font-size: 12pt;
  color: #026754;
  margin-top: -10px;
  z-index: 999;
  position: relative;
}

.tag_line {
  width: 100%;
  font-family: Titillium Web;
  font-weight: 400;
  font-size: 12px;
  color: #7f8fa6 !important;
  margin-top: 4px;
}

.restaurant_logo_div {
  width: 100px;
}

/* @media screen and (max-width: 350px) {
  .details_inner_div {
    margin-left: -4px;
  }
  .logo_info_container {
    width: 89%;
  }
  .tag_line {
    margin-left: -11px;
  }
} */
