.delivery_outer_container {
  height: 56px;
  display: flex;
  justify-content: center;
  background-color: #e0ecea;
}

@media (max-width: 500px) {
  .delivery_outer_container {
    justify-content: space-around;
  }
}

.delivery_inner_container {
  width: 90%;
  display: flex;
  color: #323f4b;
  font-family: Titillium Web;
}

.left_dropdown_container {
  width: 50%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.left_dropdown_container .dropdown-menu.show {
  transform: translate3d(33px, 29px, 0px) !important;
}

.dropdownfor-ar .dropdown-menu.show {
  transform: translate3d(-37px, 29px, 0px) !important;
}

.left_dropdown_container_heading_container {
  font-size: 10px;
  font-weight: 400;
  margin-top: 9px;
  margin-bottom: -5px;
}

.dropdown_icon_container {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: relative;
  top: 5px;
}

.dropdown_icon_container img {
  width: 12px;
  padding: 0;
  transform: matrix(0, 1, -1, 0, 0, 0);
  top: -2.5px;
  position: relative;
}

.dropdown-toggle::after {
  display: none !important;
}

.right_dropdown_container {
  width: 50%;
}

.right_dropdown_container .dropdown-menu.show {
  transform: translate3d(33px, 28px, 0px) !important;
}

.dropdown-right-ar .dropdown-menu.show {
  transform: translate3d(0px, 28px, 0px) !important;
}

.dropdown-menu.show {
  transform: translate3d(33px, 49px, 0px);
}

.dropdown-menu.show::-webkit-scrollbar {
  display: none;
}
