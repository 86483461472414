.title-cart {
  margin: 20px auto 0px !important;
}

.title-cart h3 {
  font: normal normal bold 24px/22px Titillium Web;
  color: #1f2933;
  overflow: hidden !important;
}

.dialog-content-cart {
  padding: 0px !important;
}

.time-picker {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.common-st.picker-cancel .common-st.picker-save {
  width: 85px;
  margin-top: 10px !important;
}

.picker-cancel {
  border: 1px solid #98694e !important;
  background-color: #ffffff !important;
  color: #98694e !important;
  margin-right: 10px !important;
}

.picker-save {
  border: 1px solid #707070 !important;
  background-color: #98694e !important;
  color: #ffffff !important;
}

.picker-save.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.26) !important;
  border: 1px solid rgba(0, 0, 0, 0.26) !important;
}

.date {
  display: block;
  text-align: left;
  color: #1f2933 !important;
  font-family: Montserrat !important;
  font-size: 14px !important;
  line-height: 16px !important;
  font-weight: 600 !important;
}
