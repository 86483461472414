.extra_item_outer_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: "Montserrat", sans-serif;
}

.extra_item_text_container {
  color: #1f2122;
  font-size: 10px;
  font-weight: 300;
}

.extra_item_input_stepper {
  padding: 0px 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f6f5;
  border-radius: 10px;
  color: #161d23;
}

.extra_item_input_stepper_count {
  font-size: 14px;
  font-weight: 700;
}

.extra_item_input_stepper button {
  border: none;
  background-color: transparent;
  padding: 0px;
  font-size: 18px;
  font-weight: 500;
  margin-right: 8px;
  margin-left: 8px;
}
