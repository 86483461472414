.pickup_outer_container {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  background-color: #e0ecea;
  position: relative;
}

.pickup_inner_container {
  width: 326px;
  height: 100%;
  display: flex;
  color: #323f4b;
  font-family: Titillium Web;
}

.pickup_left_dropdown_container {
  width: 163px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pickup_left_dropdown_container_heading_container,
.pickup_right_dropdown_container_heading_container {
  font-size: 10px;
  font-weight: 400;
  margin-top: 9px;
  margin-bottom: -5px;
}

.pickup_right_dropdown_container {
  width: 163px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.dropdown_icon_container {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 1px;
}

.dropdown_icon_container img {
  width: 12px;
  padding: 0;
}

.dropdown-toggle::after {
  display: none !important;
}

/* .dropdown-menu.show {
  transform: translate(0px, 37px) !important;
} */