.catering_outer_container {
  width: 100%;
  padding-top: 70px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}

.catering_inner_container {
  width: 326px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* gap: 170px; */
}

.catering_text_button_combined_container {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.catering_text_container {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: #1f2933;
}

.catering_button_container {
  margin-top: 25px;
}
