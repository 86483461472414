.reserve-container {
  padding: 0px 24px;
  margin-top: 9px;
}

.reservation-event-details {
  font-size: 12px !important;
  line-height: normal !important;
  font-family: "Titillium Web !important";
  font-weight: 600 !important;
  color: #1f2933 !important;
  width: 100%;
  padding-left: 14px;
  margin-top: 8px !important;
  text-align: left;
}

.reservation-form-container h3 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #1f2933;
  margin-bottom: 9px;
}

.reservation-form-container button {
  background-color: #98694e;
  border-radius: 12px;
  height: 42px;
  padding: 0px;
  width: 100%;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
}

.reservation-form-container button.cancel {
  background-color: #ffffff;
  border: 1px solid #98694e;
  color: #98694e;
  margin-right: 10px;
}

.reservation-form-container button.save-edit {
  border: 1px solid #98694e;
}

.reservation-form-container button.save-edit:hover {
  background-color: #805238;
}

.reservation-form-container button.reserve {
  margin-top: 12px;
}

.reservation-form-container button.reserve:hover {
  background-color: #805238;
}

.reserve-planning {
  font-size: 18px !important;
  line-height: 24px !important;
  color: #1f2933 !important;
  display: block;
}

.reserve-experience {
  color: #7f8fa6 !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  display: block;
}

.reservation-form-input {
  height: 36px !important;
  margin-bottom: 0px !important;
}

.reservation-form-input,
.reservation-form-input::placeholder {
  font: normal normal bold 12px/28px Titillium Web !important;
}

.reservation-form-container .reservation-form-input.reservation-form-time-disabled {
  background-color: rgba(189, 189, 189, 0.3) !important;
}

.reservation-form-container .reservation-form-input {
  color: #1f2933 !important;
}