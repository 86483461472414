.schedule-detail {
  display: flex;
  flex-direction: column;
}

.schedule-detail span {
  background-color: transparent;
  color: rgb(50, 63, 75);
  font-family: "Titillium Web" !important;
  font-size: 10px !important;
  font-weight: 700 !important;
  border: none;
  line-height: 15px !important;
}
.right_dropdown_container {
  width: 163px;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}
.right_dropdown_container_heading_container {
  font-size: 10px;
  font-weight: 400;
  margin-top: 9px;
  margin-bottom: -5px;
}
.dropdown_icon_container {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: relative;
  top: 5px;
}

.dropdown_icon_container img {
  width: 12px;
  padding: 0;
  transform: matrix(0, 1, -1, 0, 0, 0);
  top: -2.5px;
  position: relative;
}

.dropdown-toggle::after {
  display: none !important;
}

.right_dropdown_container .dropdown-menu.show {
  transform: translate3d(33px, 28px, 0px) !important;
}

.dropdown-menu.show {
  transform: translate3d(33px, 49px, 0px);
}

.dropdown-menu.show::-webkit-scrollbar {
  display: none;
}
