.options_services_outer_container {
  height: auto;
  background-color: white !important;
  padding-top: 20px;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
  top: 155px;
  width: 100%;
  z-index: 2;
  position: relative;
  top: -17px;
}

/* @media screen and (max-width: 500px) and (min-width: 380px) {
  .options_services_outer_container {
    top: 305px !important;
    max-width: 100% !important;
  }
}

@media screen and (max-width: 350px) {
.options_services_outer_container {
  width: 375px;
  top: 265px;
}
} */
