.menu_item_outer_container {
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: center;
  background-color: white;
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: -1px;
  z-index: 1;
  background-color: white; /* Ensure it has a background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add some shadow for better visibility */
}

.container-ar {
  padding-right: 26px !important;
  padding-left: 0px !important;
}

.container-ar .dropdown-menu.show,
.container-en .dropdown-menu.show {
  transform: translate3d(0px, 49px, 0px) !important;
}

.container-en {
  padding-left: 26px !important;
  padding-right: 0px !important;
}

/* style the scroll to be very thin */
.menu_itmes_text_container::-webkit-scrollbar {
  height: 2px;
}

.menu_item_inner_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  /* margin-left: 15px; */
}

.menu_dropdown_container {
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu_itmes_text_container {
  width: 292px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 15px;
  align-items: center;
  color: #1f2933;
  overflow-x: scroll;
  scrollbar-width: none;
  flex: 1;
}

.MuiTabs-scrollButtons {
  margin: 0px !important;
  margin-right: 4px !important;
  width: auto !important;
  margin-bottom: -4px !important;
}

@media (max-width: 499px) {
  .menu_itmes_text_container {
    width: 87%;
  }
}

.menu_itmes_text_container::-webkit-scrollbar {
  display: none;
}

.menu_itmes_text_container button {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  font-family: Titillium Web;
  font-size: 12px;
  white-space: nowrap;
  font-weight: normal;
}

.tab-ar {
  margin-left: 12px !important;
  margin-right: 0px !important;
}

.tab-en {
  margin-right: 12px;
}

.name-ar {
  text-align: right;
}

.dropdown_icon_container_menu {
  display: inline-block;
  background-color: #ebeef0;
  /* padding: 10px; */
  border-radius: 10px;
  width: 40px;
  height: 40px;
}

.dropdown_icon_container_menu img {
  width: 20px;
  padding: 0;
  margin-top: 13px;
}

.dropdown-toggle::after {
  display: none !important;
}
